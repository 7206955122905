const misc01 = require('~/assets/images/landing-page-creator/misc-01.png')
const misc02 = require('~/assets/images/landing-page-creator/misc-02.png')

const digitalArtist = require('~/assets/images/landing-page-creator/digital-artist.png')
const payment = require('~/assets/images/landing-page-creator/payment.png')
const stream = require('~/assets/images/landing-page-creator/stream.png')
const tip = require('~/assets/images/landing-page-creator/tip.png')
const wallet = require('~/assets/images/landing-page-creator/wallet.png')

const ashaPicture = require('~/assets/images/landing-page-creator/streamers/asha.png')
const chippuPicture = require('~/assets/images/landing-page-creator/streamers/chippu.png')
const istarxPicture = require('~/assets/images/landing-page-creator/streamers/istarx.png')
const jayvaaPicture = require('~/assets/images/landing-page-creator/streamers/jayvaa.png')
const ninabunsPicture = require('~/assets/images/landing-page-creator/streamers/ninabuns.png')
const seanyrbooPicture = require('~/assets/images/landing-page-creator/streamers/seanyrboo.png')

const aeonixPicture = require('~/assets/images/landing-page-creator/digital-artist/aeonix.png')
const bunbeariePicture = require('~/assets/images/landing-page-creator/digital-artist/bunbearie.png')
const cybersheepstudiosPicture = require('~/assets/images/landing-page-creator/digital-artist/cybersheepstudios.png')
const eggytamagokiPicture = require('~/assets/images/landing-page-creator/digital-artist/eggytamagoki.png')
const kendylifePicture = require('~/assets/images/landing-page-creator/digital-artist/kendylife.png')
const tofuusPicture = require('~/assets/images/landing-page-creator/digital-artist/tofuus.png')

const ellemonadePicture = require('~/assets/images/landing-page-creator/cosplayers/ellemonade.png')
const gingeralePicture = require('~/assets/images/landing-page-creator/cosplayers/gingerale.png')
const kaelcosPicture = require('~/assets/images/landing-page-creator/cosplayers/kaelcos.png')
const kirkPicture = require('~/assets/images/landing-page-creator/cosplayers/kirk.png')
const lapislazuliPicture = require('~/assets/images/landing-page-creator/cosplayers/lapislazuli.png')
const rinmineprPicture = require('~/assets/images/landing-page-creator/cosplayers/rinminepr.png')

const step1General = require('~/assets/images/landing-page-creator/step/step-1-general.png')
const step2Cosplayers = require('~/assets/images/landing-page-creator/step/step-2-cosplayers.png')
const step2DigitalArtist = require('~/assets/images/landing-page-creator/step/step-2-digital-artist.png')
const step2Streamers = require('~/assets/images/landing-page-creator/step/step-2-streamers.png')
const step3Cosplayers = require('~/assets/images/landing-page-creator/step/step-3-cosplayers.png')
const step3DigitalArtist = require('~/assets/images/landing-page-creator/step/step-3-digital-artist.png')
const step3Streamers = require('~/assets/images/landing-page-creator/step/step-3-streamers.png')
const step4General = require('~/assets/images/landing-page-creator/step/step-4-general.png')

const shioripon = require('~/assets/images/landing-page-creator/creator/shioripon.png')
const kendylife = require('~/assets/images/landing-page-creator/creator/kendylife.png')
const misakicos = require('~/assets/images/landing-page-creator/creator/misakicos.png')

const paymentindonesia = require('~/assets/images/landing-page/payment/payment-indonesia.png')
const paymentinternational = require('~/assets/images/landing-page/payment/payment-international.png')
const paymentmalaysia = require('~/assets/images/landing-page/payment/payment-malaysia.png')
const paymentphilippines = require('~/assets/images/landing-page/payment/payment-philippines.png')
const paymentsingapore = require('~/assets/images/landing-page/payment/payment-singapore.png')
const paymenttaiwan = require('~/assets/images/landing-page/payment/payment-taiwan.png')
const paymentthailand = require('~/assets/images/landing-page/payment/payment-thailand.png')
const paymentvietnam = require('~/assets/images/landing-page/payment/payment-vietnam.png')

const streamersList = [
  {
    name: 'Asha',
    username: 'asha',
    image: ashaPicture,
  },
  {
    name: 'istarx',
    username: 'istarx',
    image: istarxPicture,
  },
  {
    name: 'Chippu',
    username: 'chippu',
    image: chippuPicture,
  },
  {
    name: 'Ninabuns',
    username: 'ninabuns',
    image: ninabunsPicture,
  },
  {
    name: 'Jayvaa',
    username: 'jayvaa',
    image: jayvaaPicture,
  },
  {
    name: 'Seanyrboo',
    username: 'seanyrboo',
    image: seanyrbooPicture,
  },
]

const digitalArtistList = [
  {
    name: 'BUNBEARiE',
    username: 'bunbearie',
    image: bunbeariePicture,
  },
  {
    name: 'EggyTamagoki',
    username: 'eggytamagoki',
    image: eggytamagokiPicture,
  },
  {
    name: 'Aeonix',
    username: 'aeonix',
    image: aeonixPicture,
  },
  {
    name: 'Cyber Sheep Studios',
    username: 'cybersheepstudios',
    image: cybersheepstudiosPicture,
  },
  {
    name: 'Kendylife',
    username: 'kendylife',
    image: kendylifePicture,
  },
  {
    name: 'Tofuus',
    username: 'tofuus',
    image: tofuusPicture,
  },
]

const cosplayersList = [
  {
    name: 'KaelCos',
    username: 'kaelcosplay',
    image: kaelcosPicture,
  },
  {
    name: 'Ellemonade',
    username: 'ellemonade',
    image: ellemonadePicture,
  },
  {
    name: 'GingerAle',
    username: 'gingerale',
    image: gingeralePicture,
  },
  {
    name: 'LapisLazuli',
    username: 'lapislazuli',
    image: lapislazuliPicture,
  },
  {
    name: 'Kirk',
    username: 'kirk',
    image: kirkPicture,
  },
  {
    name: 'RinminePR',
    username: 'rinminepr',
    image: rinmineprPicture,
  },
]

const creatorStreamers = [
  {
    image: shioripon,
    imageClass: 'potrait-center',
    name: 'ShioriPon',
    testimony: 'creator_landing.testimony.streamers1',
  },
]

const creatorDigitalArtist = [
  {
    image: kendylife,
    imageClass: 'potrait-center',
    name: 'Kendylife',
    testimony: 'creator_landing.testimony.digitalartist1',
  },
]

const creatorCosplayers = [
  {
    image: misakicos,
    imageClass: 'potrait-left',
    name: 'MISAKICOS',
    testimony: 'creator_landing.testimony.cosplayers1',
  },
]

export default {
  misc01,
  misc02,
  digitalArtist,
  payment,
  stream,
  tip,
  wallet,
  streamersList,
  digitalArtistList,
  cosplayersList,
  step1General,
  step2Cosplayers,
  step2DigitalArtist,
  step2Streamers,
  step3Cosplayers,
  step3DigitalArtist,
  step3Streamers,
  step4General,
  creatorStreamers,
  creatorDigitalArtist,
  creatorCosplayers,
  paymentindonesia,
  paymentinternational,
  paymentmalaysia,
  paymentphilippines,
  paymentsingapore,
  paymenttaiwan,
  paymentthailand,
  paymentvietnam,
}
