
import { mapState } from 'vuex'

import CLPAsset from '~/repository/creatorLandingPage'

const eyeAccordionIcon = require('~/assets/images/landing-page/eye-accordion.svg')
const rocketIcon = require('~/assets/images/landing-page/rocket.svg')

const hightlightFeatureAsset2 = require('~/assets/images/landing-page/highlight-feature-2.png')
const hightlightFeatureAsset3 = require('~/assets/images/landing-page/highlight-feature-3.png')
const hightlightFeatureAsset4 = require('~/assets/images/landing-page/highlight-feature-4.png')
const hightlightFeatureAsset5 = require('~/assets/images/landing-page/highlight-feature-5.png')
const hightlightFeatureAsset6 = require('~/assets/images/landing-page/highlight-feature-6.png')
const shape011 = require('~/assets/images/landing-page/shape-01-1.png')

export default {
  data() {
    return {
      eyeAccordionIcon,
      rocketIcon,
      shape011,
    }
  },
  computed: {
    ...mapState({
      locale: state => state.settings.locale,
      countryUser: state => state.settings.countryUser,
    }),
    paymentImage() {
      const country = {
        TH: CLPAsset.paymentthailand,
        VN: CLPAsset.paymentvietnam,
        SG: CLPAsset.paymentsingapore,
        MY: CLPAsset.paymentmalaysia,
        TW: CLPAsset.paymenttaiwan,
        PH: CLPAsset.paymentphilippines,
        ID: CLPAsset.paymentindonesia,
      }

      return country[this.countryUser] ?? CLPAsset.paymentinternational
    },
    highlightFeature() {
      return [
        {
          name: '1',
          title: this.$t('landing_page.highlight_feature.1.title'),
          description: [
            this.$t('landing_page.highlight_feature.1.description_1'),
            this.$t('landing_page.highlight_feature.1.description_2'),
          ],
          image: this.paymentImage,
        },
        {
          name: '2',
          title: this.$t('landing_page.highlight_feature.2.title'),
          description: [
            this.$t('landing_page.highlight_feature.2.description_1'),
            this.$t('landing_page.highlight_feature.2.description_2'),
          ],
          image: hightlightFeatureAsset2,
        },
        {
          name: '3',
          title: this.$t('landing_page.highlight_feature.3.title'),
          description: [
            this.$t('landing_page.highlight_feature.3.description_1'),
            this.$t('landing_page.highlight_feature.3.description_2'),
          ],
          image: hightlightFeatureAsset3,
        },
        {
          name: '4',
          title: this.$t('landing_page.highlight_feature.4.title'),
          description: [
            this.$t('landing_page.highlight_feature.4.description_1'),
            this.$t('landing_page.highlight_feature.4.description_2'),
          ],
          image: hightlightFeatureAsset4,
        },
        {
          name: '5',
          title: this.$t('landing_page.highlight_feature.5.title'),
          description: [
            this.$t('landing_page.highlight_feature.5.description_1'),
          ],
          image: hightlightFeatureAsset5,
        },
        {
          name: '6',
          title: this.$t('landing_page.highlight_feature.6.title'),
          description: [
            this.$t('landing_page.highlight_feature.6.description_1'),
            this.$t('landing_page.highlight_feature.6.description_2'),
          ],
          image: hightlightFeatureAsset6,
        },
      ]
    },
  },
}
